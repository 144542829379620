import React from 'react'
import { Link } from 'gatsby'

import styles from './styles/404.module.scss'
import Layout from '../components/layout/layout'
import Container from '../components/container/container'

const NotFound = () => {
  return (
    <Layout title="Success">
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.container}>
            <h1 className={styles.title}>Success</h1>
            <div className={styles.subtitle}>
              <p>
                Thank you for subscribing to Max<sup>2</sup> Security's Cybersecurity 
                newsletter. Keep an eye on your inbox for our upcoming issue.
              </p>
              <p><Link to="/">Head home</Link></p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFound
